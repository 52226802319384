<template>
  <BaseCard
    title="datos del usuario"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <UserForm
      ref="user-form"
      v-model="user"
      password-required
    />
  </BaseCard>
</template>

<script>
import UsersApi from '@/api/users-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import UserForm from '@/components/users/form/UserForm.vue'

export default {
  components: {
    UserForm,
    BaseCard,
  },
  data() {
    return {
      loading: false,
      user: {},
    }
  },
  computed: {
    userForm() {
      return this.$refs['user-form']
    },
  },
  methods: {
    async handleSaveButtonClick() {
      const success = await this.userForm.validateForm()
      if (!success) {
        return
      }

      this.loading = true
      try {
        const response = await UsersApi.create(this.user, false)
        await this.$router.replace({ name: 'viewUser', params: { id: response.data.id } })
        this.$toast('Recurso creado con éxito.')
      } catch (error) {
        if (error.response?.data?.email) {
          this.userForm.handleUniqueEmailError(error.response.data.email)
        } else {
          console.error(error)
          this.$toast.error('Ocurrió un error al crear el recurso. Por favor inténtelo de nuevo mas tarde.')
        }
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
</style>
